import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Support: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04169 10C1.04169 5.05233 5.05234 1.04167 10 1.04167C14.9477 1.04167 18.9584 5.05233 18.9584 10C18.9584 14.9477 14.9477 18.9583 10 18.9583C9.99975 18.9583 10.0003 18.9583 10 18.9583C8.61595 18.96 7.25045 18.6397 6.01145 18.0228L6.00251 18.0184C5.84441 17.9365 5.66199 17.9148 5.48923 17.9576L5.49417 17.9562L5.33252 17.3525L5.48561 17.9585C5.48682 17.9582 5.48802 17.9579 5.48923 17.9576L3.63917 18.4529C3.34963 18.5302 3.04436 18.53 2.75496 18.4523C2.46555 18.3745 2.2017 18.222 1.98987 18.0101C1.77804 17.7981 1.62569 17.5341 1.54812 17.2447C1.47055 16.9552 1.47048 16.6505 1.54792 16.361L2.04372 14.5061C2.08508 14.3515 2.06604 14.1688 1.97684 13.9903L1.97625 13.9892C1.37847 12.7873 1.04169 11.4323 1.04169 10ZM10 2.29167C5.7427 2.29167 2.29169 5.74268 2.29169 10C2.29169 11.2344 2.58157 12.3993 3.09545 13.4325L2.53585 13.7108L3.09487 13.4313C3.30231 13.8462 3.38158 14.3419 3.25132 14.8289C3.25131 14.8289 3.25133 14.8289 3.25132 14.8289L2.75549 16.6839C2.73471 16.7616 2.7347 16.8435 2.75551 16.9211C2.77632 16.9988 2.8172 17.0696 2.87403 17.1265C2.93086 17.1833 3.00165 17.2242 3.0793 17.2451C3.15694 17.266 3.23871 17.266 3.31639 17.2453L5.17941 16.7465C5.64774 16.6282 6.14309 16.6849 6.57251 16.9058C7.63715 17.4351 8.81022 17.7099 9.99922 17.7083C14.2565 17.7083 17.7084 14.2573 17.7084 10C17.7084 5.74268 14.2573 2.29167 10 2.29167Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.04167C10.3452 6.04167 10.625 6.32149 10.625 6.66667V13.3333C10.625 13.6785 10.3452 13.9583 10 13.9583C9.65484 13.9583 9.37502 13.6785 9.37502 13.3333V6.66667C9.37502 6.32149 9.65484 6.04167 10 6.04167ZM6.66669 7.70834C7.01187 7.70834 7.29169 7.98816 7.29169 8.33334V11.6667C7.29169 12.0118 7.01187 12.2917 6.66669 12.2917C6.32151 12.2917 6.04169 12.0118 6.04169 11.6667V8.33334C6.04169 7.98816 6.32151 7.70834 6.66669 7.70834ZM13.3334 7.70834C13.6785 7.70834 13.9584 7.98816 13.9584 8.33334V11.6667C13.9584 12.0118 13.6785 12.2917 13.3334 12.2917C12.9882 12.2917 12.7084 12.0118 12.7084 11.6667V8.33334C12.7084 7.98816 12.9882 7.70834 13.3334 7.70834Z"
      />
    </SvgIcon>
  );
};

export default Support;
