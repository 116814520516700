import React, { FC } from 'react';

import { Drawer, DrawerProps, ModalProps } from '@mui/material';

import { styles } from './styles';

interface CustomDrawerProps extends DrawerProps {
  open: boolean;
  onClose?: ModalProps['onClose'];
  children?: React.ReactNode;
}

export const CustomDrawer: FC<CustomDrawerProps> = ({
  open,
  onClose,
  children,
  ...rest
}) => {
  return (
    <Drawer
      anchor={'left'}
      open={open}
      onClose={onClose}
      ModalProps={{
        sx: styles.customDrawer,
      }}
      {...rest}>
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
