import { useState, useEffect } from 'react';
import background1 from '@assets/background/lady-background1.png';
import background2 from '@assets/background/lady-background2.png';
import background3 from '@assets/background/lady-background3.png';
import background4 from '@assets/background/lady-background4.png';
import background5 from '@assets/background/lady-background5.png';
import background6 from '@assets/background/lady-background6.png';
import background7 from '@assets/background/lady-background7.png';
import background8 from '@assets/background/lady-background8.png';
import background9 from '@assets/background/lady-background9.png';
import background10 from '@assets/background/lady-background10.png';
import background11 from '@assets/background/lady-background11.png';
import background12 from '@assets/background/lady-background12.png';
import background13 from '@assets/background/lady-background13.png';
import background14 from '@assets/background/lady-background14.png';
import background15 from '@assets/background/lady-background15.png';
import background16 from '@assets/background/lady-background16.png';
import background17 from '@assets/background/lady-background17.png';
import background18 from '@assets/background/lady-background18.png';
import background19 from '@assets/background/lady-background19.png';
import background20 from '@assets/background/lady-background20.png';
import background21 from '@assets/background/lady-background21.png';

export const useRandomImage = () => {
  const images = [
    background1,
    background2,
    background3,
    background4,
    background5,
    background6,
    background7,
    background8,
    background9,
    background10,
    background11,
    background12,
    background13,
    background14,
    background15,
    background16,
    background17,
    background18,
    background19,
    background20,
    background21,
  ];

  const [randomImage, setRandomImage] = useState<string | null>(null);

  useEffect(() => {
    const getRandomImage = () => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setRandomImage(images[randomIndex]);
    };

    getRandomImage();
  }, []);

  return randomImage;
};
