export const styles = {
  root: {
    p: '24px 0 8px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    pb: '18px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: 0,
  },
};
