import { Theme } from '@mui/material/styles';

export const styles = {
  tooltip: {
    '& .MuiTooltip-tooltip': {
      padding: 0,
      borderRadius: '16px',
      backgroundColor: 'none',
      maxWidth: '100%',
    },
  },
  mobileTooltip: {
    transform: 'translate3d(-50%, 64px, 0px)!important',
    left: '50%!important',
  },
  arrow: (theme: Theme) => ({
    background: theme.palette.common.surface['surface 2'],
  }),
  dark: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
  },
};
