import React, { useEffect } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { useAppTheme } from '@themes/main';

import './App.css';
import Routers from './routes';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import * as process from 'process';
import { IntercomProvider } from 'react-use-intercom';
import { Helmet } from 'react-helmet';
import OneSignal from 'react-onesignal';
import { handleErrors } from '@helpers/handleErrors';

const PayPalConfig = {
  'client-id': process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID ?? 'sb',
  currency: 'USD',
  locale: 'en_US',
  intent: 'capture',
  vault: true,
  'disable-funding': 'mybank',
};

function App() {
  const theme = useAppTheme();
  useEffect(() => {
    const initOneSignal = async () => {
      await OneSignal.init({
        appId: process.env.REACT_APP_API_ID_ONE_SIGNAL!,
        allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
      }).then(() => {
        OneSignal.showSlidedownPrompt()
          .then()
          .catch((e: any) => {
            handleErrors(`OneSignal prompt error ${e}`);
          });
      });
    };
    OneSignal.getNotificationPermission()
      .then()
      .catch((e: any) => {
        handleErrors(`OneSignal Permission ${e}`);
      });
    initOneSignal()
      .then()
      .catch((e: any) => {
        handleErrors(`OneSignal Initialization error ${e}`);
      });
  }, []);

  return theme ? (
    <ThemeProvider theme={theme}>
      {process.env.REACT_APP_SENTRY_ENVIRONMENT === 'prod' && (
        <Helmet>
          {/*Google tag (gtag.js)*/}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-J8GSSRXJTR"></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
      
                gtag('config', 'G-J8GSSRXJTR');`}
          </script>

          {/* Meta Pixel Code */}
          <script>
            {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '1278499840163537');
              fbq('track', 'PageView');`}
          </script>
          <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1278499840163537&ev=PageView&noscript=1" alt="Meta Pixel"/>`}</noscript>
        </Helmet>
      )}
      <PayPalScriptProvider options={PayPalConfig}>
        <CssBaseline />
        <IntercomProvider
          appId={process.env.REACT_APP_PUBLIC_INTERCOM_APP_ID!}
          autoBootProps={{
            hideDefaultLauncher: true,
          }}
          autoBoot>
          <Routers />
        </IntercomProvider>
      </PayPalScriptProvider>
    </ThemeProvider>
  ) : null;
}

export default App;
