import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { profileActions } from '@bus/profile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentDataset, getIsDatasetsOpen } from '@bus/profile/selectors';
import ArrowDropDown from '@components/icons/ArrowDropDown';
import IconContainer from '@components/common/IconContainer';

type AppBarActionsProps = {};

export const AppBarActions: FC<AppBarActionsProps> = () => {
  const currentDataset = useSelector(getCurrentDataset);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isOpen = useSelector(getIsDatasetsOpen);
  const handleDatasets = () => {
    if (isOpen) {
      dispatch(profileActions.setDatasetsClosed());
    } else {
      dispatch(profileActions.setDatasetsOpen());
    }
  };

  return (
    <Box sx={styles.button} onClick={handleDatasets}>
      <Box>
        {currentDataset && (
          <IconContainer
            darkIcon={currentDataset.icon_dark}
            lightIcon={currentDataset.icon_light}
            size={24}
          />
        )}
        <Typography ml={'16px'} color={'text.secondary'} variant={'body1'}>
          {currentDataset?.name ? currentDataset.name : 'Explore Worlds'}
        </Typography>
      </Box>
      <ArrowDropDown
        sx={[styles.icon, isOpen && styles.isOpen]}
        fontSize={'small'}
        htmlColor={theme.palette.text.secondary}
      />
    </Box>
  );
};

export default AppBarActions;
