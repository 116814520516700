import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const User: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91683 2.41699C8.49009 2.41699 7.3335 3.57359 7.3335 5.00033C7.3335 6.42706 8.49009 7.58366 9.91683 7.58366C11.3436 7.58366 12.5002 6.42706 12.5002 5.00033C12.5002 3.57359 11.3436 2.41699 9.91683 2.41699ZM5.8335 5.00033C5.8335 2.74516 7.66167 0.916992 9.91683 0.916992C12.172 0.916992 14.0002 2.74516 14.0002 5.00033C14.0002 7.25549 12.172 9.08366 9.91683 9.08366C7.66167 9.08366 5.8335 7.25549 5.8335 5.00033Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57058 12.5856C4.47232 13.2034 4.00017 13.9394 4.00017 14.5835C4.00017 15.6841 4.04048 16.2328 4.55711 16.6535C4.84431 16.8874 5.33872 17.127 6.21148 17.3037C7.08092 17.4797 8.27595 17.5835 9.91684 17.5835C11.5577 17.5835 12.7528 17.4797 13.6222 17.3037C14.495 17.127 14.9894 16.8874 15.2766 16.6535C15.7932 16.2328 15.8335 15.6841 15.8335 14.5835C15.8335 13.9394 15.3614 13.2034 14.2631 12.5856C13.1884 11.9811 11.6532 11.5835 9.91684 11.5835C8.18044 11.5835 6.6453 11.9811 5.57058 12.5856ZM4.83518 11.2783C6.17338 10.5255 7.97157 10.0835 9.91684 10.0835C11.8621 10.0835 13.6603 10.5255 14.9985 11.2783C16.3132 12.0178 17.3335 13.1567 17.3335 14.5835C17.3335 14.6149 17.3335 14.6467 17.3336 14.6789C17.3349 15.6393 17.3366 16.9104 16.2238 17.8166C15.6776 18.2614 14.922 18.571 13.9198 18.7739C12.9143 18.9774 11.6093 19.0835 9.91684 19.0835C8.22439 19.0835 6.91942 18.9774 5.91387 18.7739C4.91163 18.571 4.15604 18.2614 3.60991 17.8166C2.49712 16.9104 2.49881 15.6393 2.50009 14.6789C2.50013 14.6467 2.50017 14.6149 2.50017 14.5835C2.50017 13.1567 3.52052 12.0178 4.83518 11.2783Z"
      />
    </SvgIcon>
  );
};

export default User;
