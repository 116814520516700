import { Theme } from '@mui/material/styles';

export const styles = {
  gotItTooltip: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    borderRadius: '16px',
    background: theme.palette.common.surface['surface 2'],
    padding: '17px 24px',
    minWidth: '335px',
    width: '100%',
    position: 'relative',
  }),
  iconWrapper: (theme: Theme) => ({
    maxWidth: '44px',
    width: '100%',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    background: theme.palette.action.hover,
    padding: '10px',
  }),
  button: {
    minWidth: '79px',
    padding: '14px 16px',
  },
  close: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
};
