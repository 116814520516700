import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Edit: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 16 16'}>
      <g clipPath="url(#clip0_2084_2481)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0451 2.08352C11.5487 2.08352 11.0727 2.28069 10.7218 2.63166L10.3775 2.97589C10.3985 3.06213 10.4271 3.16319 10.4659 3.27509C10.6008 3.6644 10.8564 4.17727 11.3396 4.66018C11.823 5.14357 12.3362 5.39951 12.7255 5.53463C12.837 5.57334 12.9378 5.60191 13.0239 5.62291L13.3684 5.27834C13.7194 4.92737 13.9166 4.45135 13.9166 3.955C13.9166 3.45865 13.7194 2.98263 13.3684 2.63166C13.0175 2.28069 12.5414 2.08352 12.0451 2.08352ZM11.8473 6.79937L7.0698 11.5763C6.67276 11.9733 6.51689 12.1274 6.34974 12.2578C6.14231 12.4194 5.91802 12.5582 5.68077 12.6716C5.48922 12.7626 5.2817 12.8331 4.7492 13.0104L3.0013 13.5932L2.40589 12.9978L2.98825 11.2512C3.16573 10.7188 3.23616 10.5109 3.32711 10.3194C3.44057 10.0821 3.57934 9.8578 3.741 9.65035C3.87151 9.48304 4.02547 9.32731 4.42258 8.92951L9.20069 4.15252C9.41465 4.63646 9.75114 5.1933 10.2791 5.721M10.2791 5.721C10.8068 6.24866 11.3636 6.5853 11.8473 6.79937L10.2791 5.721ZM0.835937 12.9641L1.56525 10.7768C1.57119 10.759 1.57706 10.7414 1.58284 10.724C1.73885 10.2559 1.83816 9.95782 1.97271 9.67472L1.9734 9.67326C2.13357 9.33813 2.32953 9.0213 2.55785 8.72832C2.75066 8.48108 2.97269 8.25867 3.32129 7.90954C3.33445 7.89636 3.34778 7.88301 3.3613 7.86946L9.0431 2.189L9.6611 1.571C10.2934 0.938728 11.1509 0.583519 12.0451 0.583519C12.9393 0.583519 13.7968 0.938727 14.4291 1.571C15.0614 2.20328 15.4166 3.06083 15.4166 3.955C15.4166 4.84917 15.0614 5.70672 14.4291 6.339L13.8111 6.957L8.13042 12.637L8.09636 12.6711C7.74409 13.0234 7.52083 13.2467 7.27199 13.4407C6.979 13.6691 6.66197 13.8652 6.32683 14.0254L6.32537 14.0261C6.04006 14.1617 5.74055 14.2613 5.26482 14.4197L2.50253 15.3411C2.24584 15.427 1.96954 15.44 1.70602 15.378C1.44225 15.316 1.20101 15.1816 1.00941 14.99C0.817812 14.7984 0.683452 14.5572 0.621433 14.2934C0.559449 14.0298 0.572181 13.7541 0.658196 13.4973L0.835937 12.9641ZM0.658196 13.4973L0.835937 12.9641L0.658196 13.4973Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2084_2481">
          <rect width="16" height="16" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Edit;
