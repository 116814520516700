import { Theme } from '@mui/material/styles';

export const styles = {
  appBarActions: (theme: Theme) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    '& button': {
      height: '49px',
      padding: '16px 24px',
    },
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      zIndex: 10000,
      width: '100%',
      justifyContent: 'center',
      top: '80px',
      px: '6px',
      py: '10px',
      gap: 0,
      background: theme.palette.common.surface['surface 5'],
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& button': {
        height: '49px',
        padding: '12px 8px',
      },
    },
  }),
  button: (theme: Theme) => ({
    background: theme.palette.common.surface['surface 2'],
    width: '100%',
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    borderRadius: '16px',
    padding: '8px 16px',
    [theme.breakpoints.down('lg')]: {
      height: '48px',
      borderRadius: '0 16px 16px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 8px 8px 10px',
    },
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: theme.palette.common.surface['surface 3'],
    },
  }),
  icon: {
    transform: 'rotate(0deg)',
    transition: 'all 0.3s ease',
  },
  isOpen: {
    transform: 'rotate(180deg)',
  },
};
