import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const GalleryLinear: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 24 24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.61391 2.92632C5.33582 3.0981 4.56475 3.42483 3.99424 3.99442C3.42485 4.56434 3.09812 5.33524 2.92632 6.61353C2.75159 7.91358 2.75 9.62181 2.75 12C2.75 14.3782 2.75159 16.0867 2.92632 17.3867C3.09816 18.6653 3.42496 19.4357 3.99415 20.0045C4.56411 20.5745 5.33519 20.9016 6.61359 21.0736C7.91361 21.2484 9.62179 21.25 12 21.25C14.3782 21.25 16.0866 21.2484 17.3867 21.0736C18.6651 20.9016 19.4355 20.5746 20.0043 20.005C20.5741 19.4356 20.9016 18.6646 21.0736 17.3863C21.2484 16.0864 21.25 14.3782 21.25 12C21.25 9.62179 21.2484 7.91386 21.0736 6.61398C20.9016 5.33578 20.5747 4.56471 20.0045 3.99415C19.4351 3.42487 18.6646 3.09813 17.3863 2.92632C16.0864 2.75159 14.3782 2.75 12 2.75C9.6218 2.75 7.91383 2.75159 6.61391 2.92632ZM11.9426 1.25H12.0574C14.3657 1.24999 16.1748 1.24998 17.5862 1.43968C19.0308 1.63386 20.1707 2.03906 21.0653 2.93367C21.9602 3.82911 22.3659 4.96922 22.5602 6.41402C22.75 7.82545 22.75 9.63423 22.75 11.9426V12.0574C22.75 14.3658 22.75 16.1748 22.5602 17.5862C22.3659 19.0306 21.9605 20.1705 21.0657 21.065C20.1715 21.9604 19.0314 22.3659 17.5866 22.5602C16.175 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82521 22.75 6.41366 22.5602C4.96881 22.3659 3.82871 21.9604 2.93367 21.0653C2.03898 20.1712 1.63383 19.0311 1.43968 17.5865C1.24998 16.175 1.24999 14.3657 1.25 12.0574V11.9426C1.24999 9.63425 1.24998 7.82525 1.43968 6.41372C1.63386 4.96897 2.03904 3.82891 2.93349 2.93385C3.82895 2.03961 4.96938 1.63387 6.41409 1.43968C7.82549 1.24998 9.63426 1.24999 11.9426 1.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.125C15.5168 7.125 15.125 7.51675 15.125 8C15.125 8.48325 15.5168 8.875 16 8.875C16.4832 8.875 16.875 8.48325 16.875 8C16.875 7.51675 16.4832 7.125 16 7.125ZM12.875 8C12.875 6.27411 14.2741 4.875 16 4.875C17.7259 4.875 19.125 6.27411 19.125 8C19.125 9.72589 17.7259 11.125 16 11.125C14.2741 11.125 12.875 9.72589 12.875 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.82238 12.3407L4.21498 13.4512C3.7859 13.7477 3.1337 13.7133 2.75825 13.3746C2.3828 13.0358 2.42628 12.5208 2.85537 12.2244L4.46308 11.1137C5.06316 10.6994 5.84048 10.4805 6.63711 10.5015C7.43374 10.5225 8.1905 10.7818 8.75419 11.2267L12.6909 14.335C12.6909 14.335 12.6909 14.335 12.6909 14.335C12.8246 14.4405 13.0011 14.5054 13.1894 14.5183C13.3776 14.5311 13.5656 14.4912 13.7203 14.4054L13.9949 14.2531C14.6959 13.8643 15.5432 13.6748 16.3983 13.7156C17.2534 13.7563 18.0657 14.0248 18.7025 14.4772L21.661 16.5791C22.0848 16.8802 22.1192 17.3956 21.7379 17.7302C21.3565 18.0649 20.7037 18.092 20.2799 17.7909L17.3214 15.6891C17.032 15.4834 16.6627 15.3613 16.274 15.3428C15.8853 15.3243 15.5002 15.4104 15.1816 15.5871L14.9074 15.7392C14.9073 15.7392 14.9075 15.7391 14.9074 15.7392C14.3552 16.0455 13.6836 16.1884 13.0113 16.1424C12.3388 16.0964 11.7084 15.8646 11.2311 15.4878L7.2944 12.3796C7.29437 12.3796 7.29443 12.3796 7.2944 12.3796C7.10103 12.227 6.84136 12.138 6.56811 12.1308C6.29495 12.1236 6.0282 12.1988 5.82238 12.3407Z"
      />
    </SvgIcon>
  );
};

export default GalleryLinear;
