import React, { FC } from 'react';

import {
  Box,
  ListItem,
  ListItemButton,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import { IChat } from '@bus/projects/typedefs';
import Edit from '@components/icons/Edit';
import { IconButton } from '@components/common/uiKit/IconButton';

type ActivityChatTimelineProps = {
  session: IChat;
  handleClick: (
    id: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => void;
  currentChatId: number | null;
  handleAction?: () => void;
};

export const ActivityChatTimeline: FC<ActivityChatTimelineProps> = ({
  session,
  handleClick,
  currentChatId,
  handleAction,
}) => {
  const theme = useTheme();
  const handleNavigate = (event: React.KeyboardEvent | React.MouseEvent) => {
    handleClick(session.id, event);
  };

  const editOrCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleAction?.();
  };

  return (
    <ListItem key={session.id} disablePadding sx={styles.item}>
      <ListItemButton
        onClick={handleNavigate}
        sx={[currentChatId === session.id && styles.activeItem]}>
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <Typography
            variant={'body1'}
            maxWidth={'225px'}
            color={'text.primary'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}>
            {session.name}
          </Typography>
        </Box>
        {currentChatId === session.id && (
          <IconButton
            color={'success'}
            onClick={editOrCancel}
            sx={styles.button}>
            <Edit fontSize={'small'} htmlColor={theme.palette.text.secondary} />
          </IconButton>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default ActivityChatTimeline;
