import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: [
      'Work Sans:400,500,600',
      'Poppins:400,500,600',
      'Montserrat:400,500,600',
      'Roboto:400,500,600',
      'Inter:400,500,600',
    ],
  },
});

export const typography = {
  fontFamily: "'Work Sans', 'sans-serif'",
};
