/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';

import layouts from '@layouts/index';
import { commonRoutes } from '@pages/common/routes';
import { book } from '@routes/book';
import { AppRoute } from '@setup/typedefs';
import PaymentsLayout from "@layouts/PaymentsLayout";
const HomePage = lazy(()=>import('@pages/private/HomePage'));
const ChatPage = lazy(()=>import('@pages/private/ChatPage'));
const ProfilePage = lazy(()=>import('@pages/private/ProfilePage'));
const ProfileEditingPage = lazy(()=>import('@pages/private/ProfileEditingPage'));
const NotFoundPage = lazy(()=>import('@pages/private/NotFoundPage'));
const SavedMessagesPage = lazy(()=>import('@pages/private/SavedMessagesPage'));

const ImageGalleryPage = lazy(()=>import('@pages/private/ImageGalleryPage'));
const ForUnsubscribedUsersPage = lazy(()=>import('@pages/private/ForUnsubscribedUsersPage'));
const StoryTellerPage = lazy(()=>import('@pages/private/StoryTellerPage'));
const StoryTellerHomePage = lazy(()=>import('@pages/private/StoryTellerHomePage'));
const MetametricsPage = lazy(()=>import('@pages/private/MetametricsPage'));
const MetametricsCreatePage = lazy(()=>import('@pages/private/MetametricsCreatePage'));
const MetametricsCardPage = lazy(()=>import('@pages/private/MetametricsCardPage'));
const PaymentsPage = lazy(()=>import('@pages/private/PaymentsPage'));// IMPORTS

export const privateRoutes: AppRoute<any>[] = [
  ...[
    {
      path: `${book.home}`,
      Component: <HomePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.chat}/:projectName/:chatId`,
      Component: <ChatPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.profile}`,
      Component: <ProfilePage />,
      Layout: layouts.ProfileLayout,
      layoutProps: {},
    },
    {
      path: `${book.profileEditing}`,
      Component: <ProfileEditingPage />,
      Layout: layouts.ProfileLayout,
      layoutProps: {},
    },
    {
      path: `${book.notFound}`,
      Component: <NotFoundPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.savedMessages}/:projectId`,
      Component: <SavedMessagesPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.imageGallery}/:imageId`,
      Component: <ImageGalleryPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.forUnsubscribedUsers }`,
      Component: <ForUnsubscribedUsersPage />,
      Layout: layouts.NotFoundLayout,
      layoutProps: {},
    },
    {
      path: `${book.storyTeller }/:storyId`,
      Component: <StoryTellerPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.storyTellerHome }`,
      Component: <StoryTellerHomePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.metametrics}`,
      Component: <MetametricsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.metametricsCreating}/:isPersonal`,
      Component: <MetametricsCreatePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.metametricsCard}/:metametricsId`,
      Component: <MetametricsCardPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.payments}`,
      Component: <PaymentsPage />,
      Layout: layouts.PaymentsLayout,
      layoutProps: {},
    },// INJECT
  ],
  ...commonRoutes,
];
