import { Theme } from '@mui/material/styles';

export const styles = {
  customDrawer: (theme: Theme) => ({
    '&.MuiDrawer-root': {
      zIndex: 9999,
    },
    '& .MuiPaper-root': {
      background: theme.palette.common.surface['surface 44'],
      overflowY: 'hidden',
    },
    '& .MuiBackdrop-root.MuiModal-backdrop': {
      backgroundColor: 'initial',
    },
  }),
};
