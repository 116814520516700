import React, { FC } from 'react';

import { Box, List, Typography } from '@mui/material';

import { styles } from './styles';
import ActivityChatTimeline from '@components/common/ActivityChatTimeline';
import { IMonthChat } from '@bus/projects/typedefs';

type ActivityMonthTimelineProps = {
  sessionsMont: IMonthChat;
  handleClick: (
    id: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => void;
  currentChatId: number | null;
  handleAction?: (chatId: number, name: string) => void;
};

export const ActivityMonthTimeline: FC<ActivityMonthTimelineProps> = ({
  sessionsMont,
  handleClick,
  currentChatId,
  handleAction,
}) => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleWrapper} gap={'10px'}>
        <Typography variant={'body2'} color={'text.primary'}>
          {sessionsMont.id}
        </Typography>
      </Box>

      <List sx={styles.list}>
        {sessionsMont.chats.map((session) => (
          <ActivityChatTimeline
            key={session.id}
            session={session}
            handleClick={handleClick}
            currentChatId={currentChatId}
            handleAction={() => handleAction?.(session.id, session.name)}
          />
        ))}
      </List>
    </Box>
  );
};

export default ActivityMonthTimeline;
