import React, { FC, useEffect, useState } from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import Logo from '@components/common/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { getIsGlobalSpeaking } from '@bus/ui/selectors';
import { uiActions } from '@bus/ui/actions';
import { chatActions } from '@bus/chat/actions';
import { getIsDatasetsOpen, getProfile } from '@bus/profile/selectors';
import useVoiceRecognition from '../../../hooks/useVoiceRecognition';
import { getIsAudioPlaying, getIsChatTyping } from '@bus/chat/selectors';
import {
  getIsProjectsAudioFetching,
  getProjects,
} from '@bus/projects/selectors';
import { Tooltip } from '@components/common/uiKit/Tooltip';
import AppBarActions from '@components/common/AppBarActions';
import MasterMenu from 'src/components/common/MasterMenu';
import { shortTokensAmount } from '@helpers/shortTokensAmount';
import History from '@components/icons/History';
import Payments from '@components/icons/Payments';
import User from '@components/icons/User';
import TotalCreate from '@components/common/uiKit/Tooltip/TotalCreate';
import CustomDrawer from '@components/common/uiKit/CustomDrawer';
import ChatHistoryDrawer from '@components/drawers/ChatHistoryDrawer';
import ProfileDrawer from '@components/drawers/ProfileDrawer';

type AppBarProps = {
  authorized?: boolean;
  isBackground?: boolean;
};

export const AppBar: FC<AppBarProps> = ({
  authorized = true,
  isBackground = true,
}) => {
  const theme = useTheme();
  const globalSpeaking = useSelector(getIsGlobalSpeaking);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { startListening, stopListening } = useVoiceRecognition(setIsError);
  const isTyping = useSelector(getIsChatTyping);
  const isAudioPlaying = useSelector(getIsAudioPlaying);
  const isProjectsAudioFetching = useSelector(getIsProjectsAudioFetching);
  const ref = document.getElementById('myButton');
  const projects = useSelector(getProjects);
  const isDatasetOpen = useSelector(getIsDatasetsOpen);
  const [datasets, setDatasets] = useState(projects);

  useEffect(() => {
    setDatasets(projects);
  }, [projects]);

  useEffect(() => {
    if (
      profile?.subscription &&
      profile.subscription.limits === 0 &&
      globalSpeaking
    ) {
      dispatch(uiActions.toggleGlobalSpeaking());
      dispatch(chatActions.stopVoiceDetected());

      return;
    }
    if (
      globalSpeaking &&
      !isTyping &&
      !isAudioPlaying &&
      !isProjectsAudioFetching
    ) {
      dispatch(chatActions.startVoiceDetected());
    }
  }, [profile]);

  useEffect(() => {
    if (globalSpeaking) {
      startListening();
    } else {
      stopListening();
    }
  }, []);

  useEffect(() => {
    const event = new MouseEvent('click', { bubbles: true });
    if (isError && ref) {
      ref.dispatchEvent(event);
      setTimeout(() => {
        ref.dispatchEvent(event);
      }, 100);
    }
  }, [isError, ref]);

  const toggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <>
      <Box sx={(theme) => styles.appBar(theme, isBackground)}>
        <Box sx={styles.container}>
          <Box sx={styles.leftSide}>
            <Logo />
            <Button
              variant={'drawer'}
              startIcon={
                <History
                  sx={{ fontSize: '20px' }}
                  htmlColor={theme.palette.common.surface['surface 47']}
                />
              }
              onClick={toggleDrawer('left', true)}
              sx={styles.historyButton}>
              <Typography variant={'body1'} color={'text.secondary'}>
                History
              </Typography>
            </Button>
            {authorized && (
              <Box sx={styles.appAction}>
                <AppBarActions />
              </Box>
            )}
          </Box>
          <Box sx={styles.wrapper}>
            {authorized && (
              <Box sx={styles.menuWrapper}>
                <Button
                  startIcon={
                    <User
                      sx={{ fontSize: '20px' }}
                      htmlColor={theme.palette.common.surface['surface 47']}
                    />
                  }
                  variant={'drawer'}
                  sx={styles.button}
                  onClick={toggleDrawer('right', true)}>
                  <Typography variant={'body1'} color={'text.secondary'}>
                    Profile
                  </Typography>
                </Button>
                <Tooltip
                  title={<TotalCreate tokens={profile?.tokens} />}
                  isBackground={false}
                  arrow={false}
                  mobileCenter
                  zIndex={10000}
                  placement={'bottom'}
                  id={'app-bar-tokens'}
                  open>
                  <Button
                    variant={'drawer'}
                    sx={styles.tokens}
                    endIcon={
                      <Payments
                        sx={{ fontSize: '20px' }}
                        htmlColor={theme.palette.common.surface['surface 47']}
                      />
                    }>
                    <Typography variant={'body1'} color={'text.secondary'}>
                      {shortTokensAmount(profile?.tokens ?? 0, true, false)}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      color={'text.secondary'}
                      sx={styles.tokenLabel}>
                      CREATE
                    </Typography>
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={[styles.wrapperProjects, isDatasetOpen && styles.open]}>
        <Box>
          <Box>
            <Box sx={styles.margin} />
            <MasterMenu list={datasets} />
          </Box>
        </Box>
      </Box>
      <CustomDrawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}>
        <ChatHistoryDrawer onClose={toggleDrawer('left', false)} />
      </CustomDrawer>
      <CustomDrawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}>
        <ProfileDrawer onClose={toggleDrawer('right', false)} />
      </CustomDrawer>
    </>
  );
};

export default AppBar;
