import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Bedtime: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 20'}>
      <path d="M17.7084 9.99999C17.7084 12.0444 16.8962 14.005 15.4506 15.4506C14.005 16.8962 12.0444 17.7083 10 17.7083V18.9583C14.9475 18.9583 18.9584 14.9475 18.9584 9.99999H17.7084ZM10 17.7083C7.95564 17.7083 5.995 16.8962 4.54941 15.4506C3.10381 14.005 2.29169 12.0444 2.29169 9.99999H1.04169C1.04169 14.9475 5.05252 18.9583 10 18.9583V17.7083ZM2.29169 9.99999C2.29169 7.95561 3.10381 5.99497 4.54941 4.54938C5.995 3.10378 7.95564 2.29166 10 2.29166V1.04166C5.05252 1.04166 1.04169 5.05249 1.04169 9.99999H2.29169ZM12.9167 11.875C11.6459 11.875 10.4271 11.3702 9.52847 10.4715C8.62986 9.57293 8.12502 8.35415 8.12502 7.08332H6.87502C6.87502 8.68567 7.51155 10.2224 8.64458 11.3554C9.77762 12.4885 11.3143 13.125 12.9167 13.125V11.875ZM17.0209 9.55749C16.5944 10.2647 15.9925 10.8497 15.2734 11.2557C14.5543 11.6618 13.7425 11.8751 12.9167 11.875V13.125C13.9578 13.1253 14.9814 12.8565 15.8881 12.3448C16.7948 11.833 17.5539 11.0957 18.0917 10.2042L17.0209 9.55749ZM8.12502 7.08332C8.1249 6.25751 8.33821 5.44569 8.74426 4.7266C9.15031 4.00751 9.73531 3.40556 10.4425 2.97916L9.79585 1.90916C8.90448 2.44689 8.16717 3.20583 7.65543 4.11237C7.14368 5.01892 6.87486 6.04231 6.87502 7.08332H8.12502ZM10 2.29166C9.95814 2.29085 9.91684 2.28175 9.8785 2.26487C9.84017 2.24799 9.80556 2.22367 9.77669 2.19332C9.74155 2.15803 9.71776 2.11306 9.70835 2.06416C9.70502 2.03832 9.70669 1.96332 9.79585 1.90916L10.4425 2.97916C10.8617 2.72582 10.9967 2.26166 10.9475 1.89666C10.8959 1.51749 10.5975 1.04166 10 1.04166V2.29166ZM18.0917 10.2042C18.0367 10.2933 17.9617 10.295 17.9359 10.2917C17.887 10.2823 17.842 10.2585 17.8067 10.2233C17.7763 10.1945 17.752 10.1598 17.7351 10.1215C17.7183 10.0832 17.7092 10.0419 17.7084 9.99999H18.9584C18.9584 9.40249 18.4825 9.10416 18.1034 9.05249C17.7384 9.00332 17.2742 9.13832 17.0209 9.55749L18.0917 10.2042Z" />
    </SvgIcon>
  );
};

export default Bedtime;
