import { Theme } from '@mui/material/styles';

export const styles = {
  drawerHeader: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '20px',
  }),
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
};
