import { Theme } from '@mui/material';

export const styles = {
  appBarTooltip: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    py: '8px',
  },
  button: (theme: Theme) => ({
    padding: '12px 16px',
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiTypography-root': {
      transition: 'all 0.3s ease-in-out',
    },
    '& > .MuiSvgIcon-root': {
      '& path': {
        transition: 'all 0.3s ease-in-out',
      },
    },
    '&:hover': {
      background: theme.palette.common.surface['surface 3'],
      '& .MuiTypography-root': {
        color: theme.palette.common.brand,
      },
      '& > .MuiSvgIcon-root': {
        '& path': {
          fill: theme.palette.common.brand,
        },
      },
    },
  }),
  iconWrapper: {
    display: 'flex',
    padding: '2px 4px',
    mr: '8px',
    alignItems: 'center',
  },
  switcher: (theme: Theme) => ({
    padding: 0,
    alignItems: 'center',
    height: '32px',
    width: '52px',
    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
      {
        background: theme.palette.common.surface['surface 48'],
        opacity: 1,
      },
    '& .MuiSwitch-track': {
      background: theme.palette.common.surface['surface 48'],
      height: '32px',
      borderRadius: '100px',
      opacity: 1,
      transition: 'all 0.3s ease-in-out',
    },
    '& .MuiButtonBase-root': {
      padding: '4px',
      '& .MuiSwitch-thumb': {
        background: theme.palette.common.surface['surface 3'],
        width: '24px',
        height: '24px',
      },
    },
    '&:hover': {
      '& .MuiSwitch-track': {
        background: `${theme.palette.common.brand}!important`,
      },
    },
  }),
  mobDivider: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
  head: (theme: Theme) => ({
    pb: '10px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  theme: (theme: Theme) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '24px 20px 24px 16px',
    borderTop: `4px solid ${theme.palette.common.surface['surface 5']}`,
    borderBottom: `4px solid ${theme.palette.common.surface['surface 5']}`,
  }),
};
