import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const DeleteOutline: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.31 2.25H13.69C13.907 2.25 14.096 2.25 14.274 2.278C14.6207 2.33354 14.9496 2.46946 15.2344 2.67488C15.5192 2.8803 15.7519 3.14952 15.914 3.461C15.998 3.621 16.057 3.8 16.126 4.005L16.237 4.34L16.267 4.425C16.3575 4.67615 16.526 4.89174 16.7479 5.04019C16.9697 5.18865 17.2333 5.26217 17.5 5.25H20.5C20.6989 5.25 20.8897 5.32902 21.0303 5.46967C21.171 5.61032 21.25 5.80109 21.25 6C21.25 6.19891 21.171 6.38968 21.0303 6.53033C20.8897 6.67098 20.6989 6.75 20.5 6.75H3.5C3.30109 6.75 3.11032 6.67098 2.96967 6.53033C2.82902 6.38968 2.75 6.19891 2.75 6C2.75 5.80109 2.82902 5.61032 2.96967 5.46967C3.11032 5.32902 3.30109 5.25 3.5 5.25H6.59C6.8571 5.24359 7.11513 5.15177 7.32623 4.988C7.53733 4.82423 7.6904 4.59713 7.763 4.34L7.875 4.005C7.943 3.8 8.002 3.621 8.085 3.461C8.24719 3.1494 8.48009 2.8801 8.76505 2.67468C9.05001 2.46925 9.37911 2.3334 9.726 2.278C9.904 2.25 10.093 2.25 10.309 2.25M9.007 5.25C9.07626 5.11205 9.13476 4.96896 9.182 4.822L9.282 4.522C9.373 4.249 9.394 4.194 9.415 4.154C9.46898 4.05001 9.54658 3.96011 9.64157 3.89152C9.73657 3.82292 9.84631 3.77754 9.962 3.759C10.0923 3.74746 10.2233 3.74445 10.354 3.75H13.644C13.932 3.75 13.992 3.752 14.036 3.76C14.1516 3.77843 14.2613 3.82366 14.3563 3.89208C14.4512 3.9605 14.5289 4.05019 14.583 4.154C14.604 4.194 14.625 4.249 14.716 4.523L14.816 4.823L14.855 4.935C14.8943 5.04433 14.9397 5.14933 14.991 5.25H9.007Z"
      />
      <path d="M5.91505 8.44993C5.90179 8.25141 5.81021 8.06629 5.66047 7.9353C5.51072 7.80431 5.31507 7.73816 5.11655 7.75143C4.91804 7.76469 4.73292 7.85626 4.60193 8.00601C4.47093 8.15576 4.40479 8.35141 4.41805 8.54993L4.88205 15.5019C4.96705 16.7839 5.03605 17.8199 5.19805 18.6339C5.36705 19.4789 5.65305 20.1849 6.24505 20.7379C6.83705 21.2909 7.56005 21.5309 8.41505 21.6419C9.23705 21.7499 10.2751 21.7499 11.5611 21.7499H12.4401C13.7251 21.7499 14.7641 21.7499 15.5861 21.6419C16.4401 21.5309 17.1641 21.2919 17.7561 20.7379C18.3471 20.1849 18.6331 19.4779 18.8021 18.6339C18.9641 17.8209 19.0321 16.7839 19.1181 15.5019L19.5821 8.54993C19.5953 8.35141 19.5292 8.15576 19.3982 8.00601C19.2672 7.85626 19.0821 7.76469 18.8836 7.75143C18.685 7.73816 18.4894 7.80431 18.3396 7.9353C18.1899 8.06629 18.0983 8.25141 18.0851 8.44993L17.6251 15.3499C17.5351 16.6969 17.4711 17.6349 17.3311 18.3399C17.1941 19.0249 17.0041 19.3869 16.7311 19.6429C16.4571 19.8989 16.0831 20.0649 15.3911 20.1549C14.6781 20.2479 13.7381 20.2499 12.3871 20.2499H11.6131C10.2631 20.2499 9.32305 20.2479 8.60905 20.1549C7.91705 20.0649 7.54305 19.8989 7.26905 19.6429C6.99605 19.3869 6.80605 19.0249 6.66905 18.3409C6.52905 17.6349 6.46505 16.6969 6.37505 15.3489L5.91505 8.44993Z" />
      <path d="M9.42498 10.254C9.62282 10.2342 9.82044 10.2937 9.97439 10.4195C10.1283 10.5454 10.226 10.7272 10.246 10.925L10.746 15.925C10.7606 16.1201 10.6985 16.3132 10.5728 16.4631C10.4471 16.613 10.2678 16.7078 10.0731 16.7274C9.87846 16.7469 9.68387 16.6897 9.53084 16.5678C9.37781 16.4459 9.27846 16.2691 9.25398 16.075L8.75398 11.075C8.73416 10.8771 8.7937 10.6795 8.91953 10.5256C9.04535 10.3716 9.22716 10.2739 9.42498 10.254ZM14.575 10.254C14.7726 10.2739 14.9543 10.3715 15.0801 10.5252C15.2059 10.679 15.2655 10.8763 15.246 11.074L14.746 16.074C14.7212 16.2677 14.6218 16.4441 14.469 16.5657C14.3161 16.6873 14.1219 16.7445 13.9275 16.725C13.7332 16.7056 13.5541 16.6112 13.4283 16.4618C13.3025 16.3124 13.24 16.1198 13.254 15.925L13.754 10.925C13.7739 10.7274 13.8715 10.5457 14.0252 10.4199C14.179 10.2941 14.3773 10.2344 14.575 10.254Z" />
    </SvgIcon>
  );
};

export default DeleteOutline;
