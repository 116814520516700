import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Saved: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.02756L10.3268 7.73736C9.87317 8.51177 9.113 9.06091 8.23207 9.25059L4.01202 10.1592L6.88897 13.3606C7.48954 14.0289 7.77989 14.9174 7.6891 15.8091L7.25414 20.0804L11.2054 18.3492C12.0302 17.9878 12.9698 17.9878 13.7946 18.3492L17.7459 20.0804L17.3109 15.8091C17.2201 14.9174 17.5105 14.0289 18.111 13.3606L20.988 10.1592L16.7679 9.25059C15.887 9.06091 15.1268 8.51178 14.6732 7.73736L12.5 4.02756ZM13.761 2.7207C13.1981 1.75977 11.8019 1.75977 11.239 2.7207L8.81361 6.86102C8.6074 7.21303 8.26187 7.46264 7.86145 7.54886L3.15165 8.56292C2.05855 8.79827 1.6271 10.1186 2.3723 10.9478L5.58313 14.5207C5.85612 14.8245 5.9881 15.2284 5.94683 15.6337L5.46139 20.4007C5.34873 21.5071 6.47828 22.3231 7.50175 21.8746L11.9115 19.9425C12.2865 19.7782 12.7136 19.7782 13.0885 19.9425L17.4982 21.8746C18.5217 22.3231 19.6513 21.5071 19.5386 20.4007L19.0532 15.6337C19.0119 15.2284 19.1439 14.8245 19.4169 14.5207L22.6277 10.9478C23.3729 10.1186 22.9415 8.79827 21.8483 8.56292L17.1386 7.54886C16.7381 7.46264 16.3926 7.21303 16.1864 6.86102L13.761 2.7207Z"
      />
    </SvgIcon>
  );
};

export default Saved;
