//state type____________________________________
import { ICreatedStudioImage } from '@bus/createdImages/typedefs';
import { IMessageImage } from '@bus/chat/typedefs';
import { IStoryPrompt } from '@bus/storyTeller/typedefs';
import { IMessageAudio } from '@bus/aiConversation/typedefs';

export type WsState = {
  isFetching: boolean;
  socketStatus: string;
  isClosedSockets: boolean;
  isOpenedSockets: boolean;
  isApprovedOrder: boolean;
};

//payload types_________________________________
// INJECT

//common types__________________________________
export interface IResponseChatMessage {
  action: WSAction;
  app: WSApp.chat;
  data: IResponseChatData | IResponseChatVisualizeData;
  event: WSEvent;
}
export interface IResponseStoryMessage {
  action: WSAction;
  app: WSApp.storyteller;
  data: IResponseStoryData;
  event: WSEvent;
}
export interface IResponseStudioMessage {
  action: WSAction;
  app: WSApp.image_studio;
  data: IResponseStudioData;
  event: WSEvent;
}
export interface IResponseAiMessage {
  action: WSAction;
  app: WSApp.ai;
  data: IResponseAiData | IResponseAiNotificationData;
  event: WSEvent;
}
export interface IResponseBillingMessage {
  action: WSAction;
  app: WSApp.subscription;
  data: IResponseBillingData;
  event: WSEvent;
}

export interface IResponseChatData {
  token: string;
  token_index: number;
  chat_id: number;
  chat_project_id: number;
  chat_project_name: string;
  message_project_id: number;
  message_project_name: string;
  human_message_id: number;
  message_id: number;
  next_token: boolean;
  continue_status: boolean;
}
export interface IResponseChatVisualizeData {
  action: string;
  message_id: number;
  chat_id: number;
  project_id: number;
  error: string;
  images: IMessageImage[];
}
export interface IResponseStoryData {
  action: string;
  message: {
    chat: number;
    created_at: string;
    id: number;
    image_size: string;
    project: number;
    prompt_image_regenerated: number;
    text: string | null;
    status: string;
    prompts: IStoryPrompt[];
  };
  status: boolean;
}
export interface IResponseStudioData {
  action: string;
  images: ICreatedStudioImage[];
  status: boolean;
}
export interface IResponseAiData {
  audios: IMessageAudio[];
  created_at: string;
  dataset_id: number;
  message_id: number;
  message_type: string;
  project_description: string;
  project_id: number;
  project_name: string;
  session_id: number;
  text: string;
  images: IMessageImage[];
}
export interface IResponseAiNotificationData {}
export interface IResponseBillingData {}

export enum WSApp {
  image_studio = 'image_studio',
  chat = 'chat',
  ai = 'aiconversation',
  storyteller = 'storyteller',
  subscription = 'subscription',
  metametrics = 'metametrics',
}
export enum WSEvent {
  visualize = 'visualize',
  message = 'message',
  message_continue = 'message_continue',
  face_swap = 'face_swap',
  generate_images = 'generate_images',
  process_file = 'process_file',
  generate_prompts = 'generate_prompts',
  generate_image = 'generate_image',
  billing_subscription_activated = 'billing_subscription_activated',
  billing_subscription_updated = 'billing_subscription_updated',
  checkout_order_approved = 'checkout_order_approved',
  ai_answer = 'answer',
  ai_question = 'question',
  alive = 'alive',
  session_completed = 'session_completed',
  create = 'create',
}
export enum WSAction {
  notification = 'notification',
  request = 'request',
  response = 'response',
  error = 'error',
}
