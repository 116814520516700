import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const UserProfile: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50004 6.45831C6.92474 6.45831 6.45837 6.92468 6.45837 7.49998C6.45837 8.07528 6.92474 8.54165 7.50004 8.54165C8.07534 8.54165 8.54171 8.07528 8.54171 7.49998C8.54171 6.92468 8.07534 6.45831 7.50004 6.45831ZM5.20837 7.49998C5.20837 6.23433 6.23439 5.20831 7.50004 5.20831C8.76569 5.20831 9.79171 6.23433 9.79171 7.49998C9.79171 8.76563 8.76569 9.79165 7.50004 9.79165C6.23439 9.79165 5.20837 8.76563 5.20837 7.49998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42222 11.8804C4.88575 12.1486 4.79166 12.4037 4.79166 12.5C4.79166 13.0039 4.82376 13.0811 4.94977 13.1723C5.05147 13.2459 5.26087 13.3446 5.68355 13.4206C6.10147 13.4958 6.68564 13.5416 7.5 13.5416C8.31435 13.5416 8.89852 13.4958 9.31644 13.4206C9.73913 13.3446 9.94853 13.2459 10.0502 13.1723C10.1762 13.0811 10.2083 13.0039 10.2083 12.5C10.2083 12.4037 10.1142 12.1486 9.57778 11.8804C9.07854 11.6307 8.34492 11.4583 7.5 11.4583C6.65507 11.4583 5.92146 11.6307 5.42222 11.8804ZM4.8632 10.7623C5.57021 10.4088 6.50326 10.2083 7.5 10.2083C8.49674 10.2083 9.42979 10.4088 10.1368 10.7623C10.8066 11.0972 11.4583 11.6754 11.4583 12.5C11.4583 12.5168 11.4584 12.5342 11.4584 12.5521C11.4597 12.9775 11.4619 13.6937 10.7831 14.1849C10.4681 14.4129 10.0525 14.5583 9.53772 14.6509C9.01814 14.7443 8.35231 14.7916 7.5 14.7916C6.64769 14.7916 5.98186 14.7443 5.46228 14.6509C4.94746 14.5583 4.53186 14.4129 4.21689 14.1849C3.53812 13.6937 3.54028 12.9775 3.54156 12.5521C3.54161 12.5342 3.54167 12.5168 3.54167 12.5C3.54167 11.6754 4.19341 11.0972 4.8632 10.7623Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2409 4.07482C14.3845 3.95964 13.2556 3.95831 11.6667 3.95831H8.33335C6.74453 3.95831 5.61579 3.95985 4.75956 4.07512C3.92129 4.18797 3.43808 4.39944 3.08511 4.75211C2.73241 5.10451 2.52095 5.5874 2.40819 6.42579C2.29302 7.28215 2.29169 8.41106 2.29169 9.99998C2.29169 11.5888 2.29322 12.7175 2.4085 13.5738C2.52135 14.412 2.73281 14.8953 3.08548 15.2482C3.43788 15.6009 3.92077 15.8124 4.75916 15.9251C5.61553 16.0403 6.74443 16.0416 8.33335 16.0416H11.6667C13.2555 16.0416 14.3842 16.0401 15.2405 15.9248C16.0787 15.812 16.562 15.6005 16.9149 15.2478C17.2676 14.8955 17.4791 14.4126 17.5918 13.5742C17.707 12.7178 17.7084 11.5889 17.7084 9.99998C17.7084 8.41116 17.7068 7.28242 17.5915 6.42618C17.4787 5.58792 17.2672 5.1047 16.9146 4.75173C16.5622 4.39903 16.0793 4.18758 15.2409 4.07482ZM15.4075 2.83597C16.3845 2.96738 17.1754 3.24426 17.7988 3.86823C18.422 4.49192 18.6988 5.28246 18.8304 6.2594C18.9584 7.21019 18.9584 8.42536 18.9584 9.96027V10.047C18.9584 11.5784 18.9584 12.7914 18.8307 13.7408C18.6993 14.7178 18.4224 15.5087 17.7984 16.1321C17.1747 16.7553 16.3842 17.0321 15.4073 17.1637C14.4565 17.2917 13.2413 17.2917 11.7064 17.2916H8.28631C6.75496 17.2917 5.5419 17.2917 4.59254 17.164C3.61552 17.0326 2.82466 16.7557 2.20122 16.1317C1.57806 15.508 1.30119 14.7175 1.16967 13.7406C1.04167 12.7898 1.04168 11.5746 1.04169 10.0397L1.04169 9.95295C1.04167 8.4216 1.04166 7.20853 1.16935 6.25917C1.30075 5.28215 1.57763 4.49128 2.2016 3.86785C2.8253 3.24469 3.61583 2.96782 4.59278 2.8363C5.54356 2.7083 6.75874 2.7083 8.29365 2.70831L11.7137 2.70831C13.2451 2.7083 14.4581 2.70829 15.4075 2.83597Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0417 7.49997C11.0417 7.15479 11.3215 6.87497 11.6667 6.87497H15.8334C16.1785 6.87497 16.4584 7.15479 16.4584 7.49997C16.4584 7.84515 16.1785 8.12497 15.8334 8.12497H11.6667C11.3215 8.12497 11.0417 7.84515 11.0417 7.49997ZM11.875 9.99997C11.875 9.65479 12.1548 9.37497 12.5 9.37497H15.8334C16.1785 9.37497 16.4584 9.65479 16.4584 9.99997C16.4584 10.3451 16.1785 10.625 15.8334 10.625H12.5C12.1548 10.625 11.875 10.3451 11.875 9.99997ZM12.7084 12.5C12.7084 12.1548 12.9882 11.875 13.3334 11.875H15.8334C16.1785 11.875 16.4584 12.1548 16.4584 12.5C16.4584 12.8451 16.1785 13.125 15.8334 13.125H13.3334C12.9882 13.125 12.7084 12.8451 12.7084 12.5Z"
      />
    </SvgIcon>
  );
};

export default UserProfile;
