import React from 'react';

import { PaletteMode, useMediaQuery } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import { MuiButton } from './components/MuiButtonBase';
import { MuiSvgIcon } from './components/MuiSVGIcon';
import { MuiTypography } from './components/MuiTypography';
import { mixins } from './mixins';
import { dark } from './palette/dark';
import { light } from './palette/light';
import { typography } from './typography';
import { MuiOutlinedInput } from '@themes/main/components/MuiOutlinedInput';
import { useDispatch, useSelector } from 'react-redux';
import { getTheme } from '@bus/ui/selectors';
import { uiActions } from '@bus/ui/actions';
import { ThemeVariant } from '@bus/ui/typedefs';
import { MuiIconButton } from '@themes/main/components/MuiIconButton';
import { MuiInputLabel } from '@themes/main/components/MuiInputLabel';
import { MuiFormHelperText } from '@themes/main/components/MuiFormHelperText';
import { MuiInput } from '@themes/main/components/MuiInput';

export const themeOptions: Partial<ThemeOptions> = {
  mixins,
  breakpoints,
  palette: light,
  typography,
  components: {
    ...MuiTypography,
    ...MuiButton,
    ...MuiSvgIcon,
    ...MuiOutlinedInput,
    ...MuiIconButton,
    ...MuiInputLabel,
    ...MuiFormHelperText,
    ...MuiInput,
  },
};

export const getDesignTokens = (mode: PaletteMode) => ({
  ...themeOptions,
  palette: {
    mode,
    ...(mode === 'light' ? light : dark),
  },
  components: {
    ...themeOptions.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor:
            mode === 'light'
              ? light.background?.default
              : dark.background?.default,
        },
      },
    },
  },
});
export const useAppTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useSelector(getTheme);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!theme) {
      dispatch(
        uiActions.fillTheme(
          prefersDarkMode ? ThemeVariant.dark : ThemeVariant.light,
        ),
      );
    }
  }, [theme, prefersDarkMode]);

  return React.useMemo(
    () => theme && createTheme(getDesignTokens(theme)),
    [prefersDarkMode, theme],
  );
};
