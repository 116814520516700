import React, { FC } from 'react';

import {
  Box,
  IconButton,
  ModalProps,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import Close from '@components/icons/Close';

type DrawerHeaderProps = {
  onClose?: ModalProps['onClose'];
  name: string;
  icon: React.ReactNode;
  isBorder?: boolean;
};

export const DrawerHeader: FC<DrawerHeaderProps> = ({
  onClose,
  name,
  icon,
  isBorder = true,
}) => {
  const theme = useTheme();

  const handleClick = (event: React.KeyboardEvent | React.MouseEvent) => {
    onClose?.(event, 'backdropClick');
  };

  return (
    <Box
      sx={[
        styles.drawerHeader,
        { borderBottom: isBorder ? `1px solid ${theme.palette.divider}` : 0 },
      ]}>
      <Box sx={styles.wrapper}>
        {icon}
        <Typography variant={'body1'} color={'text.secondary'}>
          {name}
        </Typography>
      </Box>
      <IconButton onClick={handleClick}>
        <Close
          fontSize={'small'}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      </IconButton>
    </Box>
  );
};

export default DrawerHeader;
