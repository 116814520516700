import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Payments: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40429 6.12014C9.42548 5.66636 10.792 5.40186 12.2678 5.40186C13.7437 5.40186 15.1102 5.66636 16.1314 6.12014C16.6412 6.34667 17.0928 6.63276 17.4254 6.9823C17.7612 7.33511 18 7.78128 18 8.29941C18 8.81754 17.7612 9.2637 17.4254 9.61651C17.0928 9.96606 16.6412 10.2521 16.1314 10.4787C15.1102 10.9325 13.7437 11.197 12.2678 11.197C10.792 11.197 9.42548 10.9325 8.40429 10.4787C7.89449 10.2521 7.4429 9.96606 7.11025 9.61651C6.77449 9.2637 6.53564 8.81754 6.53564 8.29941C6.53564 7.78128 6.77449 7.33511 7.11025 6.9823C7.4429 6.63276 7.89449 6.34667 8.40429 6.12014ZM8.02274 7.8507C7.84206 8.04055 7.79531 8.19108 7.79531 8.29941C7.79531 8.40773 7.84206 8.55826 8.02274 8.74811C8.20652 8.94123 8.50222 9.14376 8.9158 9.32754C9.74139 9.6944 10.926 9.93729 12.2678 9.93729C13.6097 9.93729 14.7943 9.6944 15.6199 9.32754C16.0335 9.14376 16.3292 8.94123 16.513 8.74811C16.6936 8.55826 16.7404 8.40773 16.7404 8.29941C16.7404 8.19108 16.6936 8.04055 16.513 7.8507C16.3292 7.65759 16.0335 7.45505 15.6199 7.27127C14.7943 6.90442 13.6097 6.66152 12.2678 6.66152C10.926 6.66152 9.74139 6.90442 8.9158 7.27127C8.50222 7.45505 8.20652 7.65759 8.02274 7.8507Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.16548 7.66943C7.51332 7.66943 7.79531 7.95142 7.79531 8.29927V15.1024C7.79531 15.2101 7.84169 15.3602 8.02125 15.5497C8.20386 15.7424 8.498 15.9449 8.91029 16.1288C9.73331 16.496 10.9176 16.7403 12.2678 16.7403C13.6181 16.7403 14.8024 16.496 15.6254 16.1288C16.0377 15.9449 16.3318 15.7424 16.5144 15.5497C16.694 15.3602 16.7404 15.2101 16.7404 15.1024V8.29927C16.7404 7.95142 17.0224 7.66943 17.3702 7.66943C17.7181 7.66943 18 7.95142 18 8.29927V15.1024C18 15.6184 17.763 16.0635 17.4287 16.4162C17.0976 16.7656 16.6476 17.0521 16.1386 17.2792C15.1191 17.734 13.7522 18 12.2678 18C10.7835 18 9.41654 17.734 8.39705 17.2792C7.88807 17.0521 7.43812 16.7656 7.10695 16.4162C6.77273 16.0635 6.53564 15.6184 6.53564 15.1024V8.29927C6.53564 7.95142 6.81763 7.66943 7.16548 7.66943Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71627 2.01045C9.39328 1.92825 11.056 2.35623 12.485 3.23815C12.781 3.42083 12.8729 3.80889 12.6902 4.1049C12.5076 4.40092 12.1195 4.4928 11.8235 4.31011C10.6073 3.55955 9.19157 3.19655 7.76426 3.26929C7.75358 3.26983 7.74289 3.2701 7.7322 3.2701C6.38789 3.2701 5.20341 3.5143 4.37857 3.88174C3.96534 4.06582 3.66998 4.26853 3.48645 4.46151C3.30587 4.6514 3.25966 4.8013 3.25966 4.90799C3.25966 5.0207 3.31243 5.18562 3.52895 5.39693C3.74743 5.61016 4.09478 5.82992 4.57107 6.02665C4.89257 6.15945 5.04555 6.52772 4.91275 6.84923C4.77996 7.17073 4.41168 7.3237 4.09018 7.19091C3.52331 6.95677 3.02027 6.66063 2.64914 6.29842C2.27605 5.9343 2 5.46426 2 4.90799C2 4.39107 2.23868 3.94568 2.57364 3.59345C2.90567 3.2443 3.35653 2.95803 3.86598 2.73109C4.88283 2.27811 6.24339 2.01235 7.71627 2.01045ZM7.16527 11.0711C7.51312 11.0711 7.7951 11.3531 7.7951 11.7009C7.7951 11.8086 7.84149 11.9587 8.02104 12.1482C8.20366 12.3409 8.4978 12.5434 8.91008 12.7273C9.73311 13.0945 10.9174 13.3388 12.2676 13.3388C13.6179 13.3388 14.8022 13.0945 15.6252 12.7273C16.0375 12.5434 16.3316 12.3409 16.5142 12.1482C16.6938 11.9587 16.7402 11.8086 16.7402 11.7009C16.7402 11.3531 17.0222 11.0711 17.37 11.0711C17.7179 11.0711 17.9998 11.3531 17.9998 11.7009C17.9998 12.2169 17.7628 12.662 17.4285 13.0147C17.0974 13.3641 16.6474 13.6506 16.1384 13.8777C15.1189 14.3326 13.752 14.5985 12.2676 14.5985C10.7833 14.5985 9.41634 14.3326 8.39684 13.8777C7.88787 13.6506 7.43791 13.3641 7.10674 13.0147C6.77252 12.662 6.53544 12.2169 6.53544 11.7009C6.53544 11.3531 6.81743 11.0711 7.16527 11.0711Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62983 4.27832C2.97768 4.27832 3.25966 4.56031 3.25966 4.90815V11.7113C3.25966 11.824 3.31243 11.9889 3.52895 12.2003C3.74743 12.4135 4.09478 12.6332 4.57107 12.83C4.89257 12.9628 5.04555 13.331 4.91275 13.6525C4.77996 13.974 4.41168 14.127 4.09018 13.9942C3.52331 13.7601 3.02027 13.464 2.64914 13.1017C2.27605 12.7376 2 12.2676 2 11.7113V4.90815C2 4.56031 2.28199 4.27832 2.62983 4.27832Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62983 7.67969C2.97768 7.67969 3.25966 7.96167 3.25966 8.30952C3.25966 8.42223 3.31243 8.58715 3.52895 8.79846C3.74743 9.01169 4.09478 9.23145 4.57107 9.42818C4.89257 9.56097 5.04555 9.92925 4.91275 10.2508C4.77996 10.5723 4.41168 10.7252 4.09018 10.5924C3.52331 10.3583 3.02027 10.0622 2.64914 9.69995C2.27605 9.33583 2 8.86579 2 8.30952C2 7.96167 2.28199 7.67969 2.62983 7.67969Z"
      />
    </SvgIcon>
  );
};

export default Payments;
