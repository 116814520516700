import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const InfoCircleLinear: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.875C12.6213 9.875 13.125 10.3787 13.125 11V17C13.125 17.6213 12.6213 18.125 12 18.125C11.3787 18.125 10.875 17.6213 10.875 17V11C10.875 10.3787 11.3787 9.875 12 9.875Z"
      />
      <path d="M12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" />
    </SvgIcon>
  );
};

export default InfoCircleLinear;
