import React, { FC, useEffect, useState } from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import ChatBubbleOutline from '@components/icons/ChatBubbleOutline';
import ActivityMonthTimeline from '@components/common/ActivityMonthTimeline';
import { IMonthChat, IYearChat } from '@bus/projects/typedefs';

type ActivityTimelineProps = {
  list: IYearChat[];
  emptyStateTitle: string;
  handleClick: (
    id: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => void;
  currentChatId: number | null;
  currentYear: number | null;
  handleAction?: (chatId: number, name: string) => void;
};

export const ActivityTimeline: FC<ActivityTimelineProps> = ({
  list,
  emptyStateTitle,
  handleClick,
  currentChatId,
  currentYear,
  handleAction,
}) => {
  const theme = useTheme();
  const [selectedMonths, setSelectedMonths] = useState<IMonthChat[]>([]);
  const [yearId, setYearId] = useState(0);

  useEffect(() => {
    if (list.length) {
      const year = list.find((item) => item.id === currentYear);
      const lastYear = list[list.length - 1];
      setYearId(year?.id ? year.id : lastYear?.id);
      setSelectedMonths(year?.months ? year?.months : lastYear?.months);
    }

    return () => {
      setSelectedMonths([]);
    };
  }, [list]);

  const handleSelectYear = (
    event: React.MouseEvent<HTMLButtonElement>,
    months: IMonthChat[],
    id: number,
  ) => {
    event.stopPropagation();
    setSelectedMonths(months);
    setYearId(id);
  };

  return (
    <Box sx={styles.root}>
      {!!list.length && (
        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          {list?.map((item) => (
            <Button
              key={item.id}
              sx={[styles.button, yearId === item.id && styles.active]}
              variant={'secondary'}
              color={'secondary'}
              onClick={(e) => handleSelectYear(e, item.months, item.id)}>
              <Typography variant={'body1'} color={'text.primary'}>
                {item.id}
              </Typography>
            </Button>
          ))}
        </Box>
      )}
      {list.length ? (
        <Box sx={styles.list}>
          {selectedMonths?.map((item) => (
            <ActivityMonthTimeline
              key={item.id}
              sessionsMont={item}
              handleClick={handleClick}
              currentChatId={currentChatId}
              handleAction={handleAction}
            />
          ))}
        </Box>
      ) : (
        <Box pb={'16px'} display={'flex'} alignItems={'center'} pl={'16px'}>
          <ChatBubbleOutline
            fontSize={'small'}
            htmlColor={theme.palette.text.primary}
          />
          <Typography
            variant={'body1'}
            color={'text.secondary'}
            ml={'8px'}
            textAlign={'center'}>
            {emptyStateTitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ActivityTimeline;
