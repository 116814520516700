import { Theme } from '@mui/material/styles';

export const styles = {
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: (theme: Theme) => ({
    width: '41px',
    height: '40px',
    [theme.breakpoints.down('lg')]: {
      width: '49px',
      height: '48px',
    },
    [theme.breakpoints.down('md')]: {
      width: '25px',
      height: '24px',
    },
  }),
};
