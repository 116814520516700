import AppLayout from './AppLayout';
import AuthLayout from './AuthLayout';
import CommonLayout from './CommonLayout';
import ProfileLayout from './ProfileLayout';
import NotFoundLayout from './NotFoundLayout';
import ConversationLayout from './ConversationLayout';

import ImageGalleryLayout from './ImageGalleryLayout';
import StoryLayout from './StoryLayout';
import HomeLayout from './HomeLayout';
import PaymentsLayout from '@layouts/PaymentsLayout';
// IMPORTS
const Layouts = {
  AppLayout,
  AuthLayout,
  CommonLayout,
  ProfileLayout,
  NotFoundLayout,
  ConversationLayout,
  ImageGalleryLayout,
  StoryLayout,
  HomeLayout,
  PaymentsLayout,
  // INJECT
};

export default Layouts;
